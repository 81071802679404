<template>
    <div>
        <a-row type="flex" justify="end" :span="16">
<!--            <a-col>-->
<!--                <a-range-picker @change="onRangePickerChange"/>-->
<!--            </a-col>-->
            <a-col :span="6" style="margin-bottom : 15px">
                <search-component v-on:change="searchJournal($event)"></search-component>
            </a-col>
        </a-row>
        <a-spin :spinning="processing">
            <a-table
                    bordered
                    :pagination="false"
                    v-if="journal.length"
                    :dataSource="journal"
                    :columns="columns"
                    :rowKey="(record) => {return record.id }"
            >
                <template slot="row_counting" slot-scope="text, record"></template>
                <template
                        slot="date"
                        slot-scope="text, record"
                >{{record.date | date }}
                </template>
                <template
                        slot="causer"
                        slot-scope="text, record"
                >{{getCauser(record.causer)}}
                </template>
                <div slot="expandedRowRender" slot-scope="record" style="background:#ECECEC;margin: 0">
                    <div style="background:#ECECEC; padding:30px">
                        <a-card title="Details" :bordered="false">
                            <div style=" padding:30px">
                                <a-row class="pd-y" type="flex" justify="space-around">
                                    <a-col :xs="{span : 7}" :md="{span : 7}" style="margin-right: 2%">
                                        Description
                                    </a-col>
                                    <a-col class="black" :xs="{span : 7}" :md="{span : 7}">
                                        {{record.description}}
                                    </a-col>
                                </a-row>
                                <hr>
                                <a-row class="pd-y" type="flex" justify="space-around">
                                    <a-col :xs="{span : 7}" :md="{span : 7}" style="margin-right: 2%">
                                        ID Utilisateur
                                    </a-col>
                                    <a-col class="black" :xs="{span : 7}" :md="{span : 7}">
                                        {{`${record.causer.id}`}}
                                    </a-col>
                                </a-row>
                                <hr>
                                <a-row class="pd-y" type="flex" justify="space-around">
                                    <a-col :xs="{span : 7}" :md="{span : 7}" style="margin-right: 2%">
                                        Utilisateur
                                    </a-col>
                                    <a-col class="black" :xs="{span : 7}" :md="{span : 7}">
                                        {{`${record.causer.nom_fr} ${record.causer.prenom_fr} ${record.causer.email}`}}
                                    </a-col>
                                </a-row>
                                <hr>
                                <a-row class="pd-y" type="flex" justify="space-around">
                                    <a-col :xs="{span : 7}" :md="{span : 7}" style="margin-right: 2%">
                                        Model
                                    </a-col>
                                    <a-col class="black" :xs="{span : 7}" :md="{span : 7}">
                                        {{`${record.name}`}}
                                    </a-col>
                                </a-row>
                              <hr>
                                <a-row class="pd-y" type="flex" justify="space-around">
                                    <a-col :xs="{span : 7}" :md="{span : 7}" style="margin-right: 2%">
                                        ID Model
                                    </a-col>
                                    <a-col class="black" :xs="{span : 7}" :md="{span : 7}">
                                        {{`${record.id}`}}
                                    </a-col>
                                </a-row>
                              <hr>
                                <pre v-html="syntaxHighlight(record.properties)">
                          </pre>

                            </div>
                        </a-card>
                    </div>
                </div>
            </a-table>
            <EmptyResult  v-else />
            <a-pagination
                    style="margin-top:1%"
                    v-if="journal.length"
                    @change="onPaginationChange"
                    @showSizeChange="onPageSizeChange"
                    :current="pagination.current"
                    :total="total"
                    :showTotal="total => `Total ${total}`"
                    :pageSize="pagination.pageSize"
                    :showSizeChanger="true"
            />
        </a-spin>
    </div>
</template>
<script>
    // import {mapState, mapActions, mapGetters, mapMutations} from "vuex";
    import {mapState, mapActions, mapMutations} from "vuex";
    import moment from "moment";
    import debounce from "lodash-es/debounce";
    import SearchComponent from "@/components/common/Search";
    import EmptyResult from "@/components/common/EmptyResult";
    // import constants from "@/const/const";

    // const ADHERENTS_URL = `/admin/adherents/edit/`;
    const columns = [
        {
            title: "ID",
            dataIndex: "id"
        },
        {
            title: "Description",
            dataIndex: "description"
        },
        {
            title: "Model",
            dataIndex: "name"
        },

        {
            title: "Date",
            scopedSlots: {customRender: "date"}
        }
    ];

    export default {
        name: "JournalIndex",
        created() {
            this.fetchData();
            this.$store.cache.dispatch("fetchExercices");
        },
        components: {
            SearchComponent,
            EmptyResult
        },
        data() {
            this.searchJournal = debounce(this.searchJournal, 1000);
            return {
                columns,
                processing: true,
                search_keyword: null,
                dtDu: null,
                dtAu: null,
            };
        },

        computed: {
            ...mapState({
                journal: state => state.logs.logs,
                total: state => state.logs.logPagination.total,
                pagination: state => state.logs.logPagination,
            })
        },
        methods: {
            moment,
            searchJournal(e) {
                this.search_keyword = e;
                this.fetchData();
            },
            getCauser(user) {
                return `${user.nom_fr} ${user.prenom_fr} (${user.email} ) `;
            },
            syntaxHighlight(obj) {
                let json = JSON.stringify(obj, undefined, 4);
                json = json.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;');
                // eslint-disable-next-line no-useless-escape
                return json.replace(/("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?)/g, function (match) {
                    var cls = 'number';
                    if (/^"/.test(match)) {
                        if (/:$/.test(match)) {
                            cls = 'key';
                        } else {
                            cls = 'string';
                        }
                    } else if (/true|false/.test(match)) {
                        cls = 'boolean';
                    } else if (/null/.test(match)) {
                        cls = 'null';
                    }
                    return '<span class="' + cls + '">' + match + '</span>';
                });
            },
            fetchData( ) {
                let params = {
                    per_page : this.pagination.pageSize,
                    page     : this.pagination.current,
                    q        : this.search_keyword,
                };
                this.processing = true;
                this.getAdditioanlData(params)
                    .then(() => (this.processing = false))
                    .catch(() => (this.processing = false));
            },
            onPaginationChange(pagination, filters, sorter) {
                this.setPagination({...this.pagination, current: pagination});
                this.fetchData();
            },
            onPageSizeChange(current, size) {
                const pager = {...this.pagination};
                pager.pageSize = size;
                this.setPagination(pager);
                this.fetchData();
            },
            onRangePickerChange(date, dateString) {
                this.dtDu = dateString[0];
                this.dtAu = dateString[1];
                this.fetchData();
            },
            ...mapMutations({
                setPagination: "setLogPagination",
                resetPagination: "setLogDefaultPagination",
            }),
            ...mapActions({
                getAdditioanlData: "fetchLog"
            })
        },
        destroyed() {
            clearInterval(this.newEntriesTimeout);
        }
    };
</script>
<style>
    .decision {
        color: #009688;
        cursor: pointer;
        text-decoration-line: underline;
    }

    .black {
        color: black !important;
    }

    .margin_right {
        margin-right: 5px;
    }

    .ant-table-thead > tr > th,
    .ant-table-tbody > tr > td {
        padding: 16px 10px 16px 10px;
        padding-top: 16px;
        padding-right: 10px;
        padding-bottom: 16px;
        padding-left: 10px;
    }

    img {
        border-radius: 50%;
        width: 84px;
        height: 84px;
        margin-right: 10px;
    }

    td {
        width: max-content;
    }

    .decision {
        text-decoration: underline;
        margin-left: 3%;
    }

    .editable-cell {
        position: relative;
    }

    .editable-cell-input-wrapper,
    .editable-cell-text-wrapper {
        padding-right: 24px;
    }

    .editable-cell-text-wrapper {
        padding: 5px 24px 5px 5px;
    }

    .editable-cell-icon,
    .editable-cell-icon-check {
        position: absolute;
        right: 0;
        width: 20px;
        cursor: pointer;
    }

    .editable-cell-icon {
        line-height: 18px;
        
    }

    .editable-cell-icon-check {
        line-height: 28px;
    }

    .editable-cell:hover .editable-cell-icon {
        display: inline-block;
    }

    .editable-cell-icon:hover,
    .editable-cell-icon-check:hover {
        color: #108ee9;
    }

    .editable-add-btn {
        margin-bottom: 20px !important;
    }

    .decission_btn {
        text-decoration: underline;
    }

    .action_icons {
        margin: 4px;
        font-size: large;
    }

    pre {
        outline: 1px solid #ccc;
        padding: 5px;
        margin: 5px;
        background-color: #282a36;
        color: white;
    }

    .string {
        color: #f1fa8c;
    }

    .number {
        color: #f1fa8c;
    }

    .boolean {
        color: #f1fa8c;
    }

    .null {
        color: #f1fa8c;
    }

    .key {
        color: #66d8ee;
    }
    hr{
      color : #ececec96;
    }
    .black{
        color: black;
    }
    .pd-y{
     padding-top: 0.5% ;
     padding-bottom: 0.5% ;
    }
</style>
